<style type="text/css">
@import "~@/assets/css/common.css";
</style>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { getMbInfo, getExamInfo } from "@/api/examination";

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "报名项目列表",
      items: [
        {
          text: "考试报名",
        },
        {
          text: "告知承诺",
          active: true,
        },
      ],
      ksbmbh: "", //考试编号
      gzcntzh: "0E4BF4D36E232918", //考试提示的模板号
      ExamInfo: {},
      MbInfo: {},
      Countdown: 3,
      kscnshList: [],
      mnIndex: 0,
    };
  },
  methods: {
    agreement(sfty) {
      let url = "";
      url = "/enrollHistory";
      this.$router.replace({
        path: url,
        query: {
          ksbmbh: this.ksbmbh,
          sfty: sfty,
        },
      });
    },
    //倒计时
    resetCode() {
      var timer = null;
      var _this = this;
      getMbInfo(this.kscnshList[this.mnIndex]).then((res) => {
        if (res.status) {
          _this.MbInfo = res.data || "";
          timer = setInterval(function () {
            _this.Countdown -= 1;

            if (_this.Countdown > 0) {
            } else {
              clearInterval(timer);
              _this.mnIndex += 1;
              if (_this.kscnshList[_this.mnIndex]) {
                _this.Countdown = 10;

                _this.resetCode();
              }
            }
          }, 1000);
        }
      });
    },
  },
  created() {
    this.ksbmbh = this.$route.query.ksbmbh;
    getExamInfo(this.ksbmbh).then((res) => {
      if (res.status) {
        this.ExamInfo = res.data;
        this.kscnshList = this.ExamInfo.kscnsh
          ? this.ExamInfo.kscnsh.split(",")
          : "";
        this.resetCode();
        // getMbInfo(this.ExamInfo.kscnsh).then((res) => {
        //   if (res.status) {
        //     this.MbInfo = res.data;
        //   }
        // });
      }
    });
  },
  mounted() {},
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px">
          <div class="card-body">
            <h4 class="card-title pb-3 border-dash">
              <span class="mr-2">正在报名：</span>
              <span class="blue-font">{{ ExamInfo.ksmc }}</span>
            </h4>
            <div class="agreement-box">
              <div class="agree-top">
                <div class="agree-title">{{MbInfo.mbmc}}</div>
              </div>
              <div class="agree-main mt-2">
                <div class="p-3 mb-3">
                  <div v-html="MbInfo.mbnr" v-if="MbInfo.mbnr"></div>
                </div>
              </div>
              <div class="agree-btn mt-2">
                <button
                  type="button"
                  class="btn btn-danger mr-5"
                  @click="agreement(true)"
                  :disabled="Countdown != 0"
                >
                  采用告知承诺制{{
                    Countdown != 0 ? Countdown + "s" : ""
                  }}
                </button>
                <button
                  type="button"
                  class="btn btn-danger bg-secondary"
                  @click="agreement(false)"
                  style="border: unset"
                  :disabled="Countdown != 0"
                >
                  不采用告知承诺制{{ Countdown != 0 ? Countdown + "s" : "" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
